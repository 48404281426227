import { useMemo } from "react";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import type { StoryblokFieldResponsiveValue } from "~types/storyblok.types";

const validBreakPoints = ["mobile", "tablet", "desktop"];

type Accumulator = Record<string, unknown>;

/**
 * filters output of `TrueLayerResponsiveField` storyblok field plugin
 * for valid arguments for `getSprinkles`'s `potentiallyInvalidResponsiveProps`
 */
export function useResponsiveFieldValue<TResponsiveSprinkles>(
  potentiallyInvalidResponsiveProps:
    | (StoryblokFieldResponsiveValue & TResponsiveSprinkles)
    | undefined
) {
  return useMemo(() => {
    if (!potentiallyInvalidResponsiveProps) {
      return undefined;
    }

    if (
      typeof potentiallyInvalidResponsiveProps === "string" ||
      typeof potentiallyInvalidResponsiveProps === "number"
    ) {
      return potentiallyInvalidResponsiveProps;
    }

    const cleanProps = Object.entries(potentiallyInvalidResponsiveProps).reduce(
      (accumulator: Accumulator, [propKey, propValue]) => {
        if (validBreakPoints.includes(propKey)) {
          accumulator[propKey] = propValue;
        }

        return accumulator;
      },
      {}
    );

    const definedResponsiveValues = Object.values(cleanProps).filter(Boolean);
    if (!checkHasLength(definedResponsiveValues)) {
      return undefined;
    }

    return cleanProps as TResponsiveSprinkles;
  }, [potentiallyInvalidResponsiveProps]);
}
