import { THEME_CONFIG } from "~config/THEME_CONFIG.config";

const { grid, phoneMockup } = THEME_CONFIG;
const { gridFullWidth, gridMinWidth } = grid;
const { phoneMaxWidth, phoneMinWidth } = phoneMockup;

export function clampBuilder(minFontSize: number, maxFontSize: number) {
  const slope = (maxFontSize - minFontSize) / (gridFullWidth - gridMinWidth);
  const yAxisIntersection = -gridMinWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`;
}

export function clampBuilderPhoneMockup(
  minFontSize: number,
  maxFontSize: number
) {
  const slope = (maxFontSize - minFontSize) / (phoneMaxWidth - phoneMinWidth);
  const yAxisIntersection = -phoneMinWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }cqw, ${maxFontSize}rem )`;
}
