/**
 * Adapted from: https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
 */
export const DESKTOP_LARGE_BREAKPOINT = 1200;
export const DESKTOP_BREAKPOINT = 992;
export const TABLET_BREAKPOINT = 720;
export const MOBILE_BREAKPOINT = 719;
export const MEDIA_QUERY_DESKTOP = `only screen and (min-width: ${DESKTOP_BREAKPOINT}px)`;
export const MEDIA_QUERY_TABLET = `only screen and (min-width: ${TABLET_BREAKPOINT}px)`;
export const MEDIA_QUERY_MOBILE = `only screen and (max-width: ${MOBILE_BREAKPOINT}px)`;

export const MEDIA_QUERY_USE_DESKTOP_NAV = MEDIA_QUERY_DESKTOP;
export const MEDIA_QUERY_USE_DESKTOP_LARGE_NAV = `only screen and (min-width: ${DESKTOP_LARGE_BREAKPOINT}px)`;

export const MEDIA_QUERY_USE_SIDEBAR = MEDIA_QUERY_DESKTOP;
export const MEDIA_QUERY_SHOW_ANIMATIONS = MEDIA_QUERY_DESKTOP;
